import i18next, { use } from 'i18next';
import { initReactI18next } from 'react-i18next';

const getLanguageFromLocalStorage = () => {
  return localStorage.getItem('language') ?? 'en'; // Use 'en' as the default language
};

void use(initReactI18next).init({
  debug: false,
  lng: getLanguageFromLocalStorage(),
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  react: {
    useSuspense: false,
  },
});

const I18NEXT = i18next;

export default I18NEXT;
