import { LoadingScreen } from '@bluemarker/ui';
import axios from 'axios';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';

import useQueryOrganizationUserProfile from '@/apis/authentication/use-query-organization-user-profile';
import useQueryUserProfile from '@/apis/authentication/use-query-user-profile';
import useQueryOrganizationProfile from '@/apis/organization/use-query-organization-profile';

import SignInAdmin from './components/sign-in-admin';
import SignInApp from './components/sign-in-app';
import SignInOrganization from './components/sign-in-organization';
import SignInPlayground from './components/sign-in-playground';

// eslint-disable-next-line sonarjs/cognitive-complexity
const SignInPage = () => {
  const navigate = useNavigate();
  const { subdomain = '' } = useParams();
  const organizationSubdomain = !['admin', 'playground'].includes(subdomain) ? subdomain : undefined;

  const { data: user, isLoading: isLoadingUser, isError: isErrorUser, error: errorUser } = useQueryUserProfile();

  const {
    data: organizationUser,
    isLoading: isLoadingOrganizationUser,
    isError: isErrorOrganizationUser,
    error: errorOrganizationUser,
  } = useQueryOrganizationUserProfile(organizationSubdomain);

  const {
    data: organizationProfile,
    isLoading: isLoadingOrganization,
    isError: isErrorOrganization,
  } = useQueryOrganizationProfile(organizationSubdomain);

  const isUnauthenticated = (error: unknown) =>
    axios.isAxiosError(error) && error.response && error.response.status === 401;

  const isUnauthenticatedUser = isErrorUser && isUnauthenticated(errorUser);
  const isUnauthenticatedOrganizationUser = isErrorOrganizationUser && isUnauthenticated(errorOrganizationUser);
  const isUnauthenticatedAdminUser = (user && !user.isAdmin) ?? isUnauthenticatedUser;

  const shouldRedirectLandingPage = subdomain === '' && user;
  const shouldRedirectPlaygroundPlatform = subdomain === 'playground' && user;
  const shouldRedirectAdminPlatform = subdomain === 'admin' && user?.isAdmin;
  const shouldRedirectTutorPlatform = subdomain && organizationUser;
  const shouldRedirect404 = organizationSubdomain && isErrorOrganization;

  useEffect(() => {
    if (isLoadingOrganizationUser || isLoadingOrganization) {
      return;
    }

    if (shouldRedirectLandingPage) {
      // TODO: UNCOMMENT WHEN SET UP APP PLATFORM
      // window.location.href = import.meta.env.VITE_APP_PLATFORM_URL;
      window.location.href = import.meta.env.VITE_LANDING_PAGE_URL;
      return;
    }

    if (shouldRedirectPlaygroundPlatform) {
      window.location.href = import.meta.env.VITE_PLAYGROUND_PLATFORM_URL;
      return;
    }

    if (shouldRedirectAdminPlatform) {
      window.location.href = import.meta.env.VITE_ADMIN_PLATFORM_URL;
      return;
    }

    if (shouldRedirectTutorPlatform) {
      window.location.href = import.meta.env.VITE_TUTOR_PLATFORM_URL.replace('sample', subdomain);
      return;
    }

    if (shouldRedirect404) {
      void navigate('/404');
    }
  }, [subdomain, isLoadingOrganizationUser, isLoadingOrganization]);

  if (
    isLoadingUser ||
    isLoadingOrganization ||
    isLoadingOrganizationUser ||
    shouldRedirectLandingPage ||
    shouldRedirectPlaygroundPlatform ||
    shouldRedirectAdminPlatform ||
    shouldRedirectTutorPlatform ||
    shouldRedirect404
  ) {
    return <LoadingScreen />;
  }

  if (organizationProfile && isUnauthenticatedOrganizationUser) {
    return <SignInOrganization organizationProfile={organizationProfile} />;
  }

  if (subdomain === 'admin' && isUnauthenticatedAdminUser) {
    return <SignInAdmin />;
  }

  if (subdomain === 'playground' && isUnauthenticatedUser) {
    return <SignInPlayground />;
  }

  return <SignInApp />;
};

export default SignInPage;
