import { HttpStatusCode } from 'axios';

import { type AuthenticationConfig, ErrorCauseEnum } from './api-config.type';

export const isAccessTokenExpiredError = (statusCode: number, cause?: string) => {
  if (!cause || !isErrorCauseEnum(cause) || !isHttpStatusCode(statusCode)) {
    return false;
  }

  return statusCode === HttpStatusCode.Unauthorized && cause === ErrorCauseEnum.ACCESS_TOKEN_EXPIRED;
};

export const isRefreshTokenExpiredError = (statusCode: number, cause?: string) => {
  if (!cause || !isErrorCauseEnum(cause) || !isHttpStatusCode(statusCode)) {
    return false;
  }

  return statusCode === HttpStatusCode.Unauthorized && cause === ErrorCauseEnum.REFRESH_TOKEN_EXPIRED;
};

export const isRequiredSystemAdminError = (statusCode: number, cause?: string) => {
  if (!cause || !isErrorCauseEnum(cause) || !isHttpStatusCode(statusCode)) {
    return false;
  }

  return statusCode === HttpStatusCode.Forbidden && cause === ErrorCauseEnum.REQUIRE_SYSTEM_ADMIN;
};

export const isRequiredAuthenticationError = (statusCode: number, cause?: string) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
  return !cause ? statusCode === HttpStatusCode.Unauthorized : false;
};

const isHttpStatusCode = (statusCode: number): statusCode is HttpStatusCode => {
  return Object.values(HttpStatusCode).includes(statusCode);
};

const isErrorCauseEnum = (cause: string): cause is ErrorCauseEnum => {
  return Object.values(ErrorCauseEnum)
    .map((value) => value.toString())
    .includes(cause);
};

export const redirectAuthentication = ({ redirectURL, subdomain }: AuthenticationConfig, sessionExpired = true) => {
  window.location.href = `${redirectURL}/${subdomain}${sessionExpired ? '?error=session-expired' : ''}`;
};
