import axios, { type AxiosInstance, type HttpStatusCode, type InternalAxiosRequestConfig } from 'axios';

import type { ApiConfig, AuthenticationConfig, ErrorCauseEnum } from './api-config.type';
import {
  isAccessTokenExpiredError,
  isRefreshTokenExpiredError,
  isRequiredAuthenticationError,
  isRequiredSystemAdminError,
  redirectAuthentication,
} from './utils';

export const initializeAxios = ({ baseURL, authentication }: ApiConfig) => {
  const API = axios.create({
    baseURL,
    withCredentials: true,
  });

  if (!authentication) return API;

  API.interceptors.response.use(
    (response) => response,
    (error) => handleResponseError(error as Error, API, authentication)
  );

  return API;
};

const handleResponseError = async (error: Error, API: AxiosInstance, authentication: AuthenticationConfig) => {
  if (!axios.isAxiosError(error)) return Promise.reject(error);

  const originalRequest = error.config as InternalAxiosRequestConfig & { _retry?: boolean };
  const { statusCode, cause } = error.response?.data as {
    message: string;
    statusCode: HttpStatusCode;
    cause?: ErrorCauseEnum;
  };

  // Retry the original request if the error is caused by access token expiration
  if (isAccessTokenExpiredError(statusCode, cause) && !originalRequest._retry) {
    originalRequest._retry = true;
    await API.post('/authentication/refresh');
    return API(originalRequest);
  }

  // Redirect to login page if the error is caused by session expiration
  if (
    [
      isAccessTokenExpiredError(statusCode, cause) && originalRequest._retry,
      isRefreshTokenExpiredError(statusCode, cause),
      isRequiredSystemAdminError(statusCode, cause),
    ].some(Boolean)
  ) {
    redirectAuthentication(authentication);
  }

  if (isRequiredAuthenticationError(statusCode, cause)) {
    redirectAuthentication(authentication, false);
  }

  return Promise.reject(error);
};
